 <!-- =========================================================================================
  File Name: ProductView.vue
  Description: Product View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="page-product-view">

    <vs-alert :active.sync="product_not_found" color="danger" title="Product Not Found">
      <span>Product record with id: {{ $route.params.productId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-product-list'}" class="text-inherit underline">All Products</router-link>
      </span>
    </vs-alert>

    <div id="product-data" v-if="product_data">

      <vx-card :title="product_data.name" class="mb-base">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="product_data.photoURL" class="rounded w-full"/>
            </div>
          </div>

          <vs-col class="flex-1">

            <!-- Information - Col 1 -->
            <vs-col vs-type="flex" vs-w="6">
              <table>
                <tr>
                  <td class="font-semibold">Product Name</td>
                  <td>{{ product_data.name }}</td>
                </tr>
              </table>
            </vs-col>
            <!-- /Information - Col 1 -->

            <!-- Information - Col 2 -->
            <vs-col vs-type="flex" vs-w="6">
              <table>
                <tr>
                  <td class="font-semibold">Product Status</td>
                  <td>{{ product_data.status | title }}</td>
                </tr>
              </table>
            </vs-col>

            <vs-col vs-type="flex" w="12">
              <vs-textarea height="125px" disabled label="Product Description" v-model="product_data.description"/>
            </vs-col>

          </vs-col>


          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex pt-5" id="account-manage-buttons">
            <vs-button :to="{name: 'app-product-edit', params: { productId: $route.params.productId }}" class="mr-4"
                       icon="icon-edit"
                       icon-pack="feather">Edit
            </vs-button>
            <vs-button class="mr-4" v-if="product_data.status !== 'archived'" @click="confirmDeleteRecord" color="danger" icon="icon-trash" icon-pack="feather" type="border">
              Archive
            </vs-button>
            <vs-button class="mr-4" v-if="product_data.status !== 'active'" @click="activateRecord" color="danger" icon="icon-play" icon-pack="feather" type="border">
              Activate
            </vs-button>
          </div>

        </div>

      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Information">
            <table>
              <tr>
                <td class="font-semibold">Type</td>
                <td>{{ product_data.type | title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ product_data.status | title }}</td>
              </tr>
              <vs-divider/>
              <tr>
                <td class="font-semibold">Stripe ID</td>
                <td>{{ product_data.stripe_id }}</td>
              </tr><tr>
                <td class="font-semibold">Service ID</td>
                <td><router-link :to="`/services/${product_data.service_id}`">{{ product_data.service_name }}</router-link></td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full" v-if="product_data.type === 'service'">
          <vx-card class="mb-base" title="Prices">
            <table>
              <tr :key="price.id" v-for="price in attributes.prices_loaded">
                <td class="font-semibold">${{ price.amount.toFixed(2) }}</td>
                <td>{{ price.display }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/2 w-full" v-else>
          <vx-card class="mb-base" title="Children">
            <table>
              <tr :key="child.id" v-for="child in attributes.children_loaded">
                <td class="font-semibold">
                  <a @click="redirectToChild(child.product_id)" :href="`/products/${child.product_id}`">
                    {{ child.name }}
                  </a>
                </td>
                <td>${{child.amount.toFixed(2) }} {{ child.display }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      product_data: null,
      product_not_found: false,
      attributes: {}
    };
  },
  methods: {
    setPhotoUrl() {
      this.product_data.photoURL = `https://portal-cdn.rapid.education/product-pictures/${this.product_data.image||'default'}.jpeg`;
    },
    loadMeta() {
      if (this.product_data.type === 'service') {

        this.$http.get(`billing/products/${this.product_data.id}/prices`)
          .then(response => {

            this.product_data.prices = response.data.data;
            const prices_loaded = this.product_data.prices
              .filter(price => !price.archived)
              .map(price => {

                price.display = 'One-Time';

                if (price.recurring) {

                  const ic = price.interval_count;
                  const plural = ic !== 1;

                  price.display = `w/ access for ${ic} ${price.interval}${plural ? 's' : ''}`;

                }

                return price;

              });

            // product_data.prices_loaded
            vm.$set(this.attributes, 'prices_loaded', prices_loaded);

          })
          .catch(exception => {

            let error = 'An unknown error occurred while loading the product list';
            if (exception.response) {
              error = exception.response.data.error.description;
            }

            return this.$vs.notify({
              title: 'Failed to load product list',
              text: error,
              color: 'danger',
              position: 'top-right',
            });
          });
      } else if (this.product_data.type === 'bundle') {

        let children = [];

        this.product_data.children_ids.forEach(async child => {

          try {
            let response = await this.$http.get(`billing/products/unknown/prices/${child}`);

            if (response.status === 200) {

              let data = response.data.data;

              response = await this.$http.get(`billing/products/${data.product_id}`);
              if (response.status === 200) {
                data.name = response.data.data.name;
              } else {
                data.name = 'Unknown'
              }

              data.display = 'One-Time';

              if (data.recurring) {

                const ic = data.interval_count;
                const plural = ic !== 1;

                data.display = `w/ access for ${ic} ${data.interval}${plural ? 's' : ''}`;

              }

              children.push(data);
            } else {
              this.$vs.notify({
                title: 'Failed to load product children',
                text: response.data.error.description,
                color: 'danger',
                position: 'top-right',
              });
            }
          } catch (e) {
            this.$vs.notify({
              title: 'Failed to load product children',
              text: 'An unknown error occurred while loading the product information',
              color: 'danger',
              position: 'top-right',
            });
          }

          this.$vs.loading.close();

        });
        vm.$set(this.attributes, 'children_loaded', children);
      }
    },
    redirectToChild(id) {
      this.$router.push(`/products/${id}`);
      window.location.reload(true);
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Archive',
        text: `Are you sure you want to archive this product?`,
        accept: this.deleteRecord,
        acceptText: 'Archive',
      });
    },
    deleteRecord() {

      this.$vs.loading();

      this.$http.delete(`billing/products/${this.product_data.id}`)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.product_data = response.data.data;
            this.setPhotoUrl();

            return this.$vs.notify({
              color: 'success',
              title: 'Product Archived',
              text: 'You have successfully archived this product',
              position: 'top-right',
            });
          }

          this.showError({response});
        })
        .catch(this.showError);

    },
    activateRecord() {

      this.$vs.loading();

      this.$http.post(`billing/products/${this.product_data.id}/activate`)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.product_data = response.data.data;
            this.setPhotoUrl();

            return this.$vs.notify({
              color: 'success',
              title: 'Product Activated',
              text: 'You have successfully set the product status to active',
              position: 'top-right',
            });
          }

          this.showError({response});
        })
        .catch(this.showError);

    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while modifying this product';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update product',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  created() {

    const productId = this.$route.params.productId;

    this.$http.get(`billing/products/${productId}`)
      .then(response => {

        if (response.data.data) {
          this.product_data = response.data.data;
          this.product_data.service_name = this.product_data.service_id;
          this.setPhotoUrl();
          this.loadMeta()
        }

        this.$http.get(`services/${this.product_data.service_id}`)
          .then(response => {
            this.product_data.service_name = response.data.data.name
          })
          .catch(exception => {

          })

      })
      .catch(error => {

        if (error.response && error.response.status === 404) {
          this.product_not_found = true;
          return;
        }
        console.error(error);
      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 15rem;
}

#page-product-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
